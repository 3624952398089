<div class="post-img d-flex justify-content-between flex-wrap gap-2 gap-lg-3">
  <!-- One Image -->
  <div class="single" *ngIf="post?.imagesList?.length === 1">
    <img *ngIf="post.imagesList[0]?.imageUrl" [src]="post.imagesList[0]?.imageUrl">
  </div>

  <!-- Two Images -->
  <div class="single d-flex gap-2" *ngIf="post?.imagesList?.length === 2">
    <div class="small-images" *ngIf="post.imagesList[0]?.imageUrl">
      <img *ngIf="post.imagesList[0]?.imageUrl" [src]="post.imagesList[0]?.imageUrl">
      <img *ngIf="post.imagesList[1]?.imageUrl" [src]="post.imagesList[1]?.imageUrl">
    </div>
  </div>

  <!-- Three Images -->
  <div class="single d-grid gap-3" *ngIf="post?.imagesList?.length === 3">
    <div class="large-image" *ngIf="post.imagesList[0]?.imageUrl">
      <img *ngIf="post.imagesList[0]?.imageUrl" [src]="post.imagesList[0]?.imageUrl">
    </div>
    <div class="small-images" *ngIf="post.imagesList[1]?.imageUrl">
      <img *ngIf="post.imagesList[1]?.imageUrl" [src]="post.imagesList[1]?.imageUrl">
      <img *ngIf="post.imagesList[2]?.imageUrl" [src]="post.imagesList[2]?.imageUrl">
    </div>
  </div>

  <!-- Four Images -->
  <div class="single d-grid gap-3" *ngIf="post?.imagesList?.length === 4">
    <div class="small-images" *ngIf="post.imagesList[0]?.imageUrl">
      <img *ngIf="post.imagesList[0]?.imageUrl" [src]="post.imagesList[0]?.imageUrl">
      <img *ngIf="post.imagesList[1]?.imageUrl" [src]="post.imagesList[1]?.imageUrl">
    </div>
    <div class="small-images" *ngIf="post.imagesList[2]?.imageUrl">
      <img *ngIf="post.imagesList[2]?.imageUrl" [src]="post.imagesList[2]?.imageUrl">
      <img *ngIf="post.imagesList[3]?.imageUrl" [src]="post.imagesList[3]?.imageUrl">
    </div>
  </div>
</div>